import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'
import { doSignOut } from '../../firebase/auth'
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText,
  } from 'reactstrap';
const Header = () => {
    const navigate = useNavigate()
    const { userLoggedIn } = useAuth()
    return (
        <Navbar className='flex flex-row gap-x-2 w-full z-20 fixed top-0 left-0 h-12 border-b place-content-center items-center bg-gray-200'>
             <NavbarBrand >
             <Link  className='text-black'  to={'/'}>
             <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/brand/wonmove.png")}
                  />
                 WonMove</Link></NavbarBrand>
             <Nav className="me-auto" navbar>

            </Nav>
            {/* <Link className='text-sm text-blue-600 underline' to={'/home'}>Workspace</Link> */}
            <NavbarText>
            {
                userLoggedIn
                    ?
                    <>
                         <Link className='text-sm text-blue-600' to={'/profile'}>Profile</Link>
                        {' '}
                        <button onClick={() => { doSignOut().then(() => { navigate('/login') }) }} className='text-sm text-blue-600 underline'>Logout</button>
                    </>
                    :
                    <>
                        <Link className='text-sm text-blue-600 underline' to={'/login'}>Login</Link>
                        {' '}
                    </>
            }
            </NavbarText>
            

        </Navbar>
    )
}

export default Header