import { db } from '../../firebase/firebase'
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from '../../contexts/authContext'
import { collection, onSnapshot, getDocs, query, where } from "firebase/firestore";
import {
    Container,
    Row,
    Col,
    Button
  } from "reactstrap";
  import { useState, useEffect } from 'react'

  const Profile = () => {
    const [subscriptions, setSubscriptions] = useState();
    const [price, setPrice] = useState();
    const [product, setProduct] = useState();
    // const [subscriptions, setSubscriptions] = useState();
    const { currentUser } = useAuth()
    const subscriptionCollectionRef = collection(db, "customers", currentUser.uid, "subscriptions");
    const activeSubscriptionRef = query(subscriptionCollectionRef, where('status', 'in', ['trialing', 'active']))
  
    useEffect(()=>{
        const getSubscription = async () => {
            const data = await getDocs(activeSubscriptionRef)
            if (data && data.docs && data.docs.length > 0) {
                       setSubscriptions(data.docs.map((elem) => ({ ...elem.data(), id: elem.id })))
                    let priceRef = data.docs[0].data().price
                       onSnapshot(priceRef, (snapshot) => {
                        // console.log(snapshot.data());          
                        setPrice(snapshot.data())  });
                    let productRef = data.docs[0].data().product
                    onSnapshot(productRef, (snapshot) => {
                        // console.log(snapshot.data());          
                        setProduct(snapshot.data())  });
                }
       }
       getSubscription()
       console.log(subscriptions)
       console.log(subscriptions && subscriptions[0].current_period_start)
    },[])

    const accessClientPortal = async() => {
        const functions = getFunctions();
        const functionRef = httpsCallable(functions,'ext-firestore-stripe-payments-createPortalLink');
const { data } = await functionRef({
  returnUrl: window.location.origin,
  locale: "auto", // Optional, defaults to "auto"
  //configuration: "test_bIY3eU0Y4cdGfKg7ss", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
});
window.location.assign(data.url);

    }


    return (
        <div>
            <section className="section section-lg">
              <Container>
            <Row>
            <Col>
            <h3>Thank you for signing up with WonMove !</h3>
            <h4>You are added to our waitlist and eligible to transfer the first $1000 with zero fees </h4>
            <h5>Confirmation code: {currentUser.uid}</h5>
            <div>
                {/* Thank you for signing up to WonMove ! */}
                We'll keep you updated on our latest features and updates.
            </div>
           
            {/* <h4>View invoices, update subscription & payment methods</h4>
            <Button onClick={accessClientPortal}>
            Access customer portal
    </Button> */}
   
    </Col>
            </Row>
        </Container>
        </section>
        </div>
    )
}

export default Profile